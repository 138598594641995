import { useCallback } from 'react';

import { BffApiUpdateItemError } from '../../../../types';
import { InvoiceID, ChargebackInvoiceCacheKeys } from '../../invoicesTypes';

import { useBffPostQuery } from '../../../common/hooks/base/reactQuery/useBffPostQuery';

import { InvoiceBffUrl } from '../../InvoiceBffUrl';

interface ChargebackInvoiceOptions {
  cacheKeys?: ChargebackInvoiceCacheKeys;
}

export type ChargebackInvoiceInputData = {
  actionOptions: {
    url: string;
  };
};

export type ChargebackInvoiceInput = {
  invoiceId: InvoiceID;
};

export type ChargebackInvoiceError = BffApiUpdateItemError;

function useChargebackInvoice<ChargebackInvoiceResponseType>({
  cacheKeys
}: ChargebackInvoiceOptions) {
  const {
    postQueryData,
    postQueryError,
    postQueryLoading,
    postQueryErrorMessage,
    postQuery,
    postQueryReset
  } = useBffPostQuery<
    ChargebackInvoiceInputData,
    ChargebackInvoiceResponseType,
    ChargebackInvoiceError
  >({ cacheKeys });

  const chargebackInvoice = useCallback<
    (input: ChargebackInvoiceInput) => Promise<unknown>
  >(
    (input) =>
      postQuery({
        actionOptions: {
          url: InvoiceBffUrl.chargeback(input.invoiceId)
        }
      }),
    [postQuery]
  );

  return {
    chargebackInvoiceData: postQueryData,
    chargebackInvoiceError: postQueryError,
    chargebackInvoiceLoading: postQueryLoading,
    chargebackInvoiceErrorMessage: postQueryErrorMessage,
    chargebackInvoice,
    chargebackInvoiceReset: postQueryReset
  };
}

export default useChargebackInvoice;

import { gql } from 'graphql-request';

export const CREATE_PAYPAL_ATTEMPT_QUERY = gql`
  mutation CreatePaypalAttempt(
    $invoiceId: ID!
    $withPaymentUrl: Boolean
    $withOrderId: Boolean
  ) {
    createPaypalAttempt(
      input: {
        invoiceId: $invoiceId
        withPaymentUrl: $withPaymentUrl
        withOrderId: $withOrderId
      }
    ) {
      appVersion
      errors {
        fullMessages
      }
      record {
        id
        paymentUrl
        orderId
      }
      recordId
      status
    }
  }
`;

import { BffApiCreateItemError } from '../../../../types';

import {
  PayoneerV4AttemptInvoiceID,
  PayoneerV4AttemptPaymentUrl,
  CreatePayoneerV4AttemptCacheKeys
} from '../../payoneerV4AttemptsTypes';

import { useBffCreateQuery } from '../../../common/hooks/base/reactQuery/useBffCreateQuery';

import { PayoneerV4AttemptBffUrl } from '../../PayoneerV4AttemptBffUrl';

interface CreatePayoneerV4AttemptOptions {
  cacheKeys?: CreatePayoneerV4AttemptCacheKeys;
}

export interface CreatePayoneerV4AttemptInput {
  payoneerV4Attempt: {
    invoiceId: PayoneerV4AttemptInvoiceID;
  };
}

export type CreatePayoneerV4AttemptError = BffApiCreateItemError;

interface CreatePayoneerV4AttemptResponseType {
  paymentUrl: PayoneerV4AttemptPaymentUrl;
}

function useCreatePayoneerV4Attempt({
  cacheKeys
}: CreatePayoneerV4AttemptOptions = {}) {
  const { createQuery, createQueryErrorMessage, createQueryLoading } =
    useBffCreateQuery<
      CreatePayoneerV4AttemptInput,
      CreatePayoneerV4AttemptResponseType,
      CreatePayoneerV4AttemptError,
      CreatePayoneerV4AttemptResponseType
    >({ url: PayoneerV4AttemptBffUrl.create(), cacheKeys });

  return {
    createPayoneerV4AttemptLoading: createQueryLoading,
    createPayoneerV4AttemptErrorMessage: createQueryErrorMessage,
    createPayoneerV4Attempt: createQuery
  };
}

export default useCreatePayoneerV4Attempt;

import React from 'react';
import { Controller, FieldPath, Control } from 'react-hook-form';

import {
  InputMoneyFieldControl,
  InputMoneyFieldControlRequiredProps
} from './components/InputMoneyFieldControl';

interface InputMoneyFieldRequiredProps<T> {
  control: Control<T>;
  name: FieldPath<T>;
}

function InputMoneyField<T>({
  control,
  disabled,
  max,
  inputClassName,
  name
}: InputMoneyFieldRequiredProps<T> & InputMoneyFieldControlRequiredProps) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <InputMoneyFieldControl
          value={value as number | null}
          error={error?.message}
          disabled={disabled}
          max={max}
          inputClassName={inputClassName}
          name={name}
          onChange={onChange}
        />
      )}
    />
  );
}

export default InputMoneyField;

import { useCallback } from 'react';

import { BffApiUpdateItemError } from '../../../../types';
import { InvoiceID, VoidInvoiceCacheKeys } from '../../invoicesTypes';

import { useBffPostQuery } from '../../../common/hooks/base/reactQuery/useBffPostQuery';

import { InvoiceBffUrl } from '../../InvoiceBffUrl';

interface VoidInvoiceOptions {
  cacheKeys?: VoidInvoiceCacheKeys;
}

export type VoidInvoiceInputData = {
  actionOptions: {
    url: string;
  };
};

export type VoidInvoiceInput = {
  invoiceId: InvoiceID;
};

export type VoidInvoiceError = BffApiUpdateItemError;

function useVoidInvoice<VoidInvoiceResponseType>({
  cacheKeys
}: VoidInvoiceOptions) {
  const {
    postQueryData,
    postQueryError,
    postQueryLoading,
    postQueryErrorMessage,
    postQuery,
    postQueryReset
  } = useBffPostQuery<
    VoidInvoiceInputData,
    VoidInvoiceResponseType,
    VoidInvoiceError
  >({ cacheKeys });

  const voidInvoice = useCallback<
    (input: VoidInvoiceInput) => Promise<unknown>
  >(
    (input) =>
      postQuery({
        actionOptions: {
          url: InvoiceBffUrl.void(input.invoiceId)
        }
      }),
    [postQuery]
  );

  return {
    voidInvoiceData: postQueryData,
    voidInvoiceError: postQueryError,
    voidInvoiceLoading: postQueryLoading,
    voidInvoiceErrorMessage: postQueryErrorMessage,
    voidInvoice,
    voidInvoiceReset: postQueryReset
  };
}

export default useVoidInvoice;

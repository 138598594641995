import { BffApiUpdateItemError } from '../../../../types';

import {
  PaypalAttemptOrderID,
  CheckAndPayPaypalByOrderIdCacheKeys,
  CheckAndPayPaypalByOrderIdGqlStatus
} from '../../paypalAttemptsTypes';

import { useFinUpdateQuery } from '../../../common/hooks/base/reactQuery/useFinUpdateQuery';
import { CHECK_AND_PAY_PAYPAL_BY_ORDER_ID_QUERY } from '../../queries/checkAndPayPaypalByOrderId.query';

interface CheckAndPayPaypalByOrderIdOptions {
  cacheKeys?: CheckAndPayPaypalByOrderIdCacheKeys;
}

interface CheckAndPayPaypalByOrderIdInput {
  orderId: PaypalAttemptOrderID;
}

interface CheckAndPayPaypalByOrderIdResponse {
  checkAndPayPaypalByOrderId: {
    status: CheckAndPayPaypalByOrderIdGqlStatus;
    content?: {
      details?: {
        issue?: string;
        description?: string;
      }[];
      message?: string;
      debug_id?: string;
      purchase_units?: unknown[];
    };
  };
}

type CheckAndPayPaypalByOrderIdError = BffApiUpdateItemError;

const action = 'checkAndPayPaypalByOrderId';

function useCheckAndPayPaypalByOrderId({
  cacheKeys
}: CheckAndPayPaypalByOrderIdOptions = {}) {
  const { updateQuery, updateQueryErrorMessage, updateQueryLoading } =
    useFinUpdateQuery<
      CheckAndPayPaypalByOrderIdInput,
      CheckAndPayPaypalByOrderIdResponse,
      CheckAndPayPaypalByOrderIdError,
      void
    >({
      action,
      query: CHECK_AND_PAY_PAYPAL_BY_ORDER_ID_QUERY,
      cacheKeys
    });

  return {
    checkAndPayPaypalByOrderIdLoading: updateQueryLoading,
    checkAndPayPaypalByOrderIdErrorMessage: updateQueryErrorMessage,
    checkAndPayPaypalByOrderId: updateQuery
  };
}

export default useCheckAndPayPaypalByOrderId;

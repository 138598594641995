import { PayoneerV4AttemptID } from './payoneerV4AttemptsTypes';

const baseUrl = 'payoneer_v4_attempts';

export class PayoneerV4AttemptBffUrl {
  static index() {
    return baseUrl;
  }

  static create() {
    return baseUrl;
  }

  static checkAndPay(payoneerV4AttemptId: PayoneerV4AttemptID) {
    return `${baseUrl}/${payoneerV4AttemptId}/check_and_pay`;
  }
}

import { BffApiCreateItemError, UpdateItemGqlError } from '../../../../types';

import {
  StripeAttemptID,
  StripeAttemptPaymentUrl,
  StripeAttemptSessionClientSecret,
  StripeAttemptWithPaymentUrl,
  StripeAttemptWithSessionClientSecret,
  CreateStripeAttemptCacheKeys
} from '../../stripeAttemptsTypes';

import { InvoiceID } from '../../../invoices/invoicesTypes';

import { useFinCreateQuery } from '../../../common/hooks/base/reactQuery/useFinCreateQuery';
import { CREATE_STRIPE_ATTEMPT_QUERY } from '../../queries/createStripeAttempt.query';
import { UpdateBillGqlStatus } from '../../../bills/billsTypes';

interface CreateStripeAttemptOptions {
  cacheKeys?: CreateStripeAttemptCacheKeys;
}

interface CreateStripeAttemptInput {
  invoiceId: InvoiceID;
  withPaymentUrl?: StripeAttemptWithPaymentUrl;
  withSessionClientSecret?: StripeAttemptWithSessionClientSecret;
}

type CreateStripeAttemptError = BffApiCreateItemError;

interface CreateStripeAttemptItemType {
  id: StripeAttemptID;
  paymentUrl: StripeAttemptPaymentUrl;
  sessionClientSecret: StripeAttemptSessionClientSecret;
}

interface CreateStripeAttemptResponseType {
  createStripeAttempt: {
    status: UpdateBillGqlStatus;
    record: CreateStripeAttemptItemType;
    errors: UpdateItemGqlError;
    recordId: InvoiceID;
  };
}

const action = 'createStripeAttempt';

function useCreateStripeAttempt({
  cacheKeys
}: CreateStripeAttemptOptions = {}) {
  const { createQuery, createQueryErrorMessage, createQueryLoading } =
    useFinCreateQuery<
      CreateStripeAttemptInput,
      CreateStripeAttemptResponseType,
      CreateStripeAttemptError,
      CreateStripeAttemptItemType
    >({ action, query: CREATE_STRIPE_ATTEMPT_QUERY, cacheKeys });

  return {
    createStripeAttemptLoading: createQueryLoading,
    createStripeAttemptErrorMessage: createQueryErrorMessage,
    createStripeAttempt: createQuery
  };
}

export default useCreateStripeAttempt;

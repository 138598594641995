import React, { Fragment, useCallback, ChangeEventHandler } from 'react';

import isFinite from 'lodash/isFinite';
import isNumber from 'lodash/isNumber';
import round from 'lodash/round';
import lodashMin from 'lodash/min';
import lodashMax from 'lodash/max';

import { FieldIcons } from '../../../../../types';

import { IconsEnum } from '../../../../../assets/icons/types';

import { Translate } from '../../../../../helpers/Translate';
import { Icon } from '../../../../../helpers/Icon';

import { InputMoneyFieldControlRequiredProps } from './InputMoneyFieldControl.types';

const MIN_VALUE = 0;

interface InputMoneyFieldControlProps {
  value: number | null;
  onChange: (value: number | null) => void;
}

function InputMoneyFieldControl({
  value,
  error,
  disabled,
  max,
  inputClassName,
  name,
  onChange
}: InputMoneyFieldControlProps & InputMoneyFieldControlRequiredProps) {
  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const parsedValue = parseFloat(event.target.value);
      if (!isFinite(parsedValue)) {
        onChange(null);
        return;
      }
      onChange(lodashMax([lodashMin([round(parsedValue, 2), max]), MIN_VALUE]));
    },
    [onChange, max]
  );

  return (
    <Fragment>
      <div className="relative">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <span className="text-gray-500 sm:text-sm">{FieldIcons.DOLLAR}</span>
        </div>
        <input
          className={inputClassName || 'basic-input pl-7 pr-4'}
          disabled={disabled}
          max={max}
          min={MIN_VALUE}
          placeholder="0.00"
          name={name}
          type="number"
          value={isNumber(value) ? value : ''}
          onChange={handleChange}
        />
      </div>
      {error ? (
        <p className="mt-1 text-red-500 text-2xs align-center">
          <Icon
            className="h-5 w-5 text-red-500"
            icon={IconsEnum.EXCLAMATION_CIRCLE}
          />
          <span>
            {/^forms\.errors+/.test(error) ? <Translate id={error} /> : error}
          </span>
        </p>
      ) : null}
    </Fragment>
  );
}

export default InputMoneyFieldControl;

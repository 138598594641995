import { gql } from 'graphql-request';

export const CREATE_STRIPE_ATTEMPT_QUERY = gql`
  mutation CreateStripeAttempt(
    $invoiceId: ID!
    $withPaymentUrl: Boolean
    $withSessionClientSecret: Boolean
  ) {
    createStripeAttempt(
      input: {
        invoiceId: $invoiceId
        withPaymentUrl: $withPaymentUrl
        withSessionClientSecret: $withSessionClientSecret
      }
    ) {
      appVersion
      errors {
        fullMessages
      }
      record {
        id
        paymentUrl
        sessionClientSecret
      }
      recordId
      status
    }
  }
`;

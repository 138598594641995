import { useCallback, useState } from 'react';

import { InvoiceNanoID, InvoiceID } from '../../../../../invoicesTypes';
import { AvBillingInfoPaymentMethods } from '../../../../../../avBillingInfos/avBillingInfosTypes';

import {
  FETCH_INVOICE_QUERY,
  FetchInvoiceQueryResponse
} from '../../../../../queries/fetchInvoice.query';

import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';

import { useFinInvoice } from '../../../../../hooks/useFinInvoice';

import { PayoneerCheckoutAttemptPaymentDataLinksSelf } from '../../../../../../payoneerCheckoutAttempts/payoneerCheckoutAttemptsTypes';
import { PayoneerAttemptPaymentUrl } from '../../../../../../payoneerAttempts/payoneerAttemptsTypes';

import { useCreatePayoneerAttempt } from '../../../../../../payoneerAttempts/hooks/useCreatePayoneerAttempt';
import { useCreatePayoneerV4Attempt } from '../../../../../../payoneerV4Attempts/hooks/useCreatePayoneerV4Attempt';
import { useCreatePayoneerCheckoutAttempt } from '../../../../../../payoneerCheckoutAttempts/hooks/useCreatePayoneerCheckoutAttempt';

import { InvoiceCache } from '../../../../../InvoiceCache';
import { InvoicesPermissions } from '../../../../../invoicesConstants';

interface UsePayInvoiceModalButtonOptions {
  invoiceNanoId: InvoiceNanoID;
}

function usePayInvoiceModalButton({
  invoiceNanoId
}: UsePayInvoiceModalButtonOptions) {
  const currentUser = useCurrentUser();

  const usePayoneerV4Button = currentUser.hasPermissions(
    InvoicesPermissions.READ_INVOICE_PAYONEER_V4_PAY_BUTTON
  );

  const [refetchingInvoice, setRefetchingInvoice] = useState<boolean>(false);

  const [payoneerCheckoutListUrl, setPayoneerCheckoutListUrl] =
    useState<PayoneerCheckoutAttemptPaymentDataLinksSelf>(null);

  const [payoneerPaymentUrl, setPayoneerPaymentUrl] =
    useState<PayoneerAttemptPaymentUrl>(null);

  const [payoneerV4PaymentUrl, setPayoneerV4PaymentUrl] =
    useState<PayoneerAttemptPaymentUrl>(null);

  const { invoice, invoiceError, invoiceLoading, refetchInvoice } =
    useFinInvoice<FetchInvoiceQueryResponse>({
      invoiceUuid: invoiceNanoId,
      query: FETCH_INVOICE_QUERY,
      cacheKey: InvoiceCache.showCacheKey(),
      options: {
        enabled: false,
        enabledPlaceholder: false
      }
    });

  const {
    createPayoneerCheckoutAttemptLoading,
    createPayoneerCheckoutAttemptErrorMessage,
    createPayoneerCheckoutAttempt
  } = useCreatePayoneerCheckoutAttempt();

  const {
    createPayoneerAttemptLoading,
    createPayoneerAttemptErrorMessage,
    createPayoneerAttempt
  } = useCreatePayoneerAttempt();

  const {
    createPayoneerV4AttemptLoading,
    createPayoneerV4AttemptErrorMessage,
    createPayoneerV4Attempt
  } = useCreatePayoneerV4Attempt();

  const handleInitializePayoneerCheckoutPayment = useCallback<
    (invoiceId: InvoiceID) => void
  >(
    async (invoiceId) => {
      const response = await createPayoneerCheckoutAttempt({
        payoneerCheckoutAttempt: {
          invoiceId
        }
      });
      setPayoneerCheckoutListUrl(response?.paymentData?.links?.self);
    },
    [createPayoneerCheckoutAttempt]
  );

  const handleInitializePayoneerPayment = useCallback<
    (invoiceId: InvoiceID) => void
  >(
    async (invoiceId) => {
      const response = await createPayoneerAttempt({
        payoneerAttempt: {
          invoiceId
        }
      });
      if (response?.paymentUrl) {
        setPayoneerPaymentUrl(response.paymentUrl);
        return;
      }
    },
    [createPayoneerAttempt]
  );

  const handleInitializePayoneerV4Payment = useCallback<
    (invoiceId: InvoiceID) => void
  >(
    async (invoiceId) => {
      const response = await createPayoneerV4Attempt({
        payoneerV4Attempt: {
          invoiceId
        }
      });
      if (response?.paymentUrl) {
        setPayoneerV4PaymentUrl(response.paymentUrl);
        return;
      }
    },
    [createPayoneerV4Attempt]
  );

  const handleSubmit = useCallback<() => Promise<void>>(async () => {
    if (usePayoneerV4Button && payoneerV4PaymentUrl) {
      window.open(payoneerV4PaymentUrl);
      return;
    }

    if (!usePayoneerV4Button && payoneerPaymentUrl) {
      window.open(payoneerPaymentUrl);
      return;
    }

    return;
  }, [usePayoneerV4Button, payoneerPaymentUrl, payoneerV4PaymentUrl]);

  const handleOpen = useCallback<() => void>(async () => {
    try {
      setPayoneerCheckoutListUrl(null);
      setPayoneerPaymentUrl(null);
      setPayoneerV4PaymentUrl(null);
      setRefetchingInvoice(true);
      const refetchResponse = await refetchInvoice();
      setRefetchingInvoice(false);

      const fetchedInvoice = refetchResponse?.data?.invoice;
      if (!fetchedInvoice) {
        return;
      }

      if (
        fetchedInvoice.avInvoiceBillingInfo?.paymentMethod ===
        AvBillingInfoPaymentMethods.PAYONEER_CHECKOUT
      ) {
        handleInitializePayoneerCheckoutPayment(fetchedInvoice.id);
      }
      if (
        fetchedInvoice.avInvoiceBillingInfo?.paymentMethod ===
          AvBillingInfoPaymentMethods.PAYONEER &&
        !usePayoneerV4Button
      ) {
        handleInitializePayoneerPayment(fetchedInvoice.id);
      }
      if (
        fetchedInvoice.avInvoiceBillingInfo?.paymentMethod ===
          AvBillingInfoPaymentMethods.PAYONEER &&
        usePayoneerV4Button
      ) {
        handleInitializePayoneerV4Payment(fetchedInvoice.id);
      }
    } catch (e) {
      setRefetchingInvoice(false);
    }
  }, [
    usePayoneerV4Button,
    handleInitializePayoneerCheckoutPayment,
    handleInitializePayoneerPayment,
    handleInitializePayoneerV4Payment,
    refetchInvoice
  ]);

  const isPayoneerCheckoutForm =
    invoice?.avInvoiceBillingInfo?.paymentMethod ===
    AvBillingInfoPaymentMethods.PAYONEER_CHECKOUT;

  const isStripeForm =
    invoice?.avInvoiceBillingInfo?.paymentMethod ===
    AvBillingInfoPaymentMethods.STRIPE;

  const isPaypalForm =
    invoice?.avInvoiceBillingInfo?.paymentMethod ===
    AvBillingInfoPaymentMethods.PAYPAL;

  return {
    invoice,
    errorMessage:
      createPayoneerCheckoutAttemptErrorMessage ||
      createPayoneerAttemptErrorMessage ||
      createPayoneerV4AttemptErrorMessage ||
      invoiceError,
    isLoading:
      createPayoneerCheckoutAttemptLoading ||
      createPayoneerAttemptLoading ||
      createPayoneerV4AttemptLoading ||
      invoiceLoading ||
      refetchingInvoice,
    isPayoneerCheckoutForm,
    isStripeForm,
    isPaypalForm,
    payoneerCheckoutListUrl,
    handleOpen,
    handleSubmit
  };
}

export default usePayInvoiceModalButton;

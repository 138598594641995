import { useCallback } from 'react';

import { BffApiUpdateItemError } from '../../../../types';
import {
  InvoiceID,
  CancelInvoiceSmartContractsCacheKeys
} from '../../invoicesTypes';

import { useBffPostQuery } from '../../../common/hooks/base/reactQuery/useBffPostQuery';

import { InvoiceBffUrl } from '../../InvoiceBffUrl';

interface CancelInvoiceSmartContractsOptions {
  cacheKeys?: CancelInvoiceSmartContractsCacheKeys;
}

export type CancelInvoiceSmartContractsInputData = {
  actionOptions: {
    url: string;
  };
};

export type CancelInvoiceSmartContractsInput = {
  invoiceId: InvoiceID;
};

export type CancelInvoiceSmartContractsError = BffApiUpdateItemError;

function useCancelInvoiceSmartContracts<
  CancelInvoiceSmartContractsResponseType
>({ cacheKeys }: CancelInvoiceSmartContractsOptions) {
  const {
    postQueryData,
    postQueryError,
    postQueryLoading,
    postQueryErrorMessage,
    postQuery,
    postQueryReset
  } = useBffPostQuery<
    CancelInvoiceSmartContractsInputData,
    CancelInvoiceSmartContractsResponseType,
    CancelInvoiceSmartContractsError
  >({ cacheKeys });

  const cancelInvoiceSmartContracts = useCallback<
    (input: CancelInvoiceSmartContractsInput) => Promise<unknown>
  >(
    (input) =>
      postQuery({
        actionOptions: {
          url: InvoiceBffUrl.cancelSmartContracts(input.invoiceId)
        }
      }),
    [postQuery]
  );

  return {
    cancelInvoiceSmartContractsData: postQueryData,
    cancelInvoiceSmartContractsError: postQueryError,
    cancelInvoiceSmartContractsLoading: postQueryLoading,
    cancelInvoiceSmartContractsErrorMessage: postQueryErrorMessage,
    cancelInvoiceSmartContracts,
    cancelInvoiceSmartContractsReset: postQueryReset
  };
}

export default useCancelInvoiceSmartContracts;

import cl from 'classnames';
import round from 'lodash/round';

import {
  ErrorMessage,
  RegisterFormFieldType,
  ClassName
} from '../../../../../types';
import { InvoiceAmount, PayInvoiceIsLoading } from '../../../invoicesTypes';
import {
  ConfirmPaymentInvoiceFields,
  ConfirmPaymentInvoiceFormData
} from './ConfirmPaymentInvoiceForm.types';

import { Form } from '../../../../../helpers/Form';
import { InputField } from '../../../../../helpers/FormFields/InputField';
import { MoneyHelper } from '../../../../../helpers/MoneyHelper';
import { SelectFieldRequiredProps } from '../../../../../helpers/FormFields/SelectField';
import { InputMoneyField } from '../../../../../helpers/FormFields/InputMoneyField';
import { Translate } from '../../../../../helpers/Translate';
import { TextareaAutosizeField } from '../../../../../helpers/FormFields/TextareaAutosizeField';

import {
  smartContractsKeys,
  invoicesKeys,
  words
} from '../../../../../locales/keys';

interface ConfirmPaymentInvoiceFormProps {
  invoiceAmount: InvoiceAmount;
  form: string;
  innerWrapperClassName?: ClassName;
  isLoading: PayInvoiceIsLoading;
  registerPaymentDate: RegisterFormFieldType<HTMLInputElement>;
  registerConfirmationId: RegisterFormFieldType<HTMLInputElement>;
  paymentDateValidationError: ErrorMessage;
  confirmationIdValidationError: ErrorMessage;
  watchTransactionFee: number;
}

function ConfirmPaymentInvoiceForm({
  invoiceAmount,
  form,
  control,
  innerWrapperClassName,
  isLoading,
  registerPaymentDate,
  registerConfirmationId,
  paymentDateValidationError,
  confirmationIdValidationError,
  watchTransactionFee
}: ConfirmPaymentInvoiceFormProps &
  SelectFieldRequiredProps<ConfirmPaymentInvoiceFormData>) {
  return (
    <Form className="flex-1 overflow-y-auto px-2" id={form}>
      <div className={cl(innerWrapperClassName || 'p-4 space-y-4')}>
        <div className="grid grid-cols-[auto_1fr] gap-3">
          <div className="sm:text-sm">
            <Translate id={words.amountDue} />:
          </div>
          <div className="sm:text-sm">
            <MoneyHelper value={invoiceAmount} />
          </div>
          <div className="sm:text-sm">
            <Translate id={smartContractsKeys.transactionFee} />:
          </div>
          <div className="w-full">
            <InputMoneyField
              control={control}
              name={ConfirmPaymentInvoiceFields.TRANSACTION_FEE}
              max={invoiceAmount}
            />
          </div>
          <div className="sm:text-sm">
            <Translate id={smartContractsKeys.transactionFee} /> (
            {round(
              (((watchTransactionFee || 0) * 100) / (invoiceAmount * 100)) *
                100,
              2
            )}
            %):
          </div>
          <div className="sm:text-sm">
            <MoneyHelper value={watchTransactionFee || 0} />
          </div>
          <div className="sm:text-sm">
            <Translate id={invoicesKeys.receivedAmount} />:
          </div>
          <div className="sm:text-sm">
            <MoneyHelper value={invoiceAmount - (watchTransactionFee || 0)} />
          </div>
          <div className="sm:text-sm">
            <Translate id={invoicesKeys.paymentDate} />:
          </div>
          <InputField
            error={paymentDateValidationError}
            inputClassName="border-0 border-b border-gray-300 dark:border-gray-700 p-0 text-sm focus:ring-0 focus:border-blue-500 dark:bg-gray-850 w-full"
            type="date"
            name={registerPaymentDate.name}
            onChange={registerPaymentDate.onChange}
            ref={registerPaymentDate.ref}
            disabled={isLoading}
          />
          <div className="sm:text-sm">
            <Translate id={words.itc} />:
          </div>
          <InputField
            error={confirmationIdValidationError}
            inputClassName="border-0 border-b border-gray-300 dark:border-gray-700 p-0 text-sm focus:ring-0 focus:border-blue-500 dark:bg-gray-850 w-full"
            name={registerConfirmationId.name}
            onChange={registerConfirmationId.onChange}
            ref={registerConfirmationId.ref}
            disabled={isLoading}
          />
          <div className="sm:text-sm">
            <Translate id={words.comment} />:
          </div>
          <TextareaAutosizeField<ConfirmPaymentInvoiceFormData>
            name={ConfirmPaymentInvoiceFields.COMMENT}
            control={control}
            disabled={isLoading}
            maxRows={10}
            minRows={1}
            pasteAsMarkdown
            className="border-0 border-b border-gray-300 dark:border-gray-700 p-0 text-sm focus:ring-0 focus:border-blue-500 dark:bg-gray-850 w-full"
          />
        </div>
      </div>
    </Form>
  );
}

export default ConfirmPaymentInvoiceForm;

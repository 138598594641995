import { useMutation, useQueryClient } from 'react-query';
import isArray from 'lodash/isArray';

import {
  UpdateItemGqlQuery,
  UpdateItemCacheKeys
} from '../../../../../../types';

import { updateFinItem } from '../baseActions/updateFinItem';

import {
  ErrorType,
  parseRequestError
} from '../../../../../../utils/parseRequestError';

interface UpdateQueryOptions<UpdateQueryInput, ItemType> {
  action: string;
  query: UpdateItemGqlQuery;
  cacheKeys?: UpdateItemCacheKeys;
  onOptimisticUpdate?: (input: UpdateQueryInput) => null | (() => void);
  onSuccess?: (responseRecord: ItemType) => void;
}

interface UpdateQueryContext {
  rollback?: () => void;
}

function useFinUpdateQuery<
  UpdateQueryInput,
  UpdateQueryResponse,
  UpdateQueryError extends ErrorType,
  ItemType
>({
  action,
  query,
  cacheKeys,
  onOptimisticUpdate,
  onSuccess
}: UpdateQueryOptions<UpdateQueryInput, ItemType>) {
  const queryClient = useQueryClient();

  const { data, error, isLoading, mutateAsync, reset } = useMutation<
    UpdateQueryResponse,
    UpdateQueryError,
    UpdateQueryInput,
    UpdateQueryContext
  >(
    (queryInput) =>
      updateFinItem<UpdateQueryInput, UpdateQueryResponse>({
        action,
        query,
        queryInput
      }),
    {
      onMutate: (input) => {
        const rollback = onOptimisticUpdate?.(input) || undefined;

        return { rollback };
      },
      onError: (error, variables, context) => {
        context?.rollback?.();
      },
      onSuccess: (response) => {
        onSuccess?.(response?.[action]?.record);
      },
      onSettled: async (response) => {
        if (isArray(cacheKeys)) {
          cacheKeys.map((eachCacheKey) =>
            queryClient.invalidateQueries(eachCacheKey)
          );
        }
      }
    }
  );

  return {
    updateQueryData: data,
    updateQueryError: error,
    updateQueryLoading: isLoading,
    updateQueryErrorMessage: parseRequestError(error),
    updateQuery: mutateAsync,
    updateQueryReset: reset
  };
}

export default useFinUpdateQuery;

import { ClassName, I18nText, IsDisabled } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';
import {
  FetchInvoicesCacheKeys,
  InvoiceAmount,
  InvoiceID
} from '../../../invoicesTypes';

import { useTranslate } from '../../../../../common/hooks/useTranslate';
import { useConfirmPaymentInvoiceForm } from '../../forms/ConfirmPaymentInvoiceForm/hooks/useConfirmPaymentInvoiceForm';

import { ConfirmPaymentInvoiceForm } from '../../forms/ConfirmPaymentInvoiceForm';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { FormModalButton } from '../../../../../helpers/buttons/FormModalButton';

import { invoicesKeys } from '../../../../../locales/keys';
import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';

const CONFIRM_PAYMENT_INVOICE_FORM = 'confirmPayment-invoice-form';

interface ConfirmPaymentInvoiceModalButtonProps {
  cacheKeys?: FetchInvoicesCacheKeys;
  className?: ClassName;
  disabled?: IsDisabled;
  i18nText?: I18nText;
  i18nTextClassName?: ClassName;
  invoiceId: InvoiceID;
  invoiceAmount: InvoiceAmount;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  tooltipPlacement?: TooltipPlacement;
  tooltipI18nText?: I18nText;
}

function ConfirmPaymentInvoiceModalButton({
  cacheKeys,
  className,
  disabled,
  i18nText,
  i18nTextClassName,
  invoiceId,
  invoiceAmount,
  icon,
  iconClassName,
  tooltipPlacement,
  tooltipI18nText
}: ConfirmPaymentInvoiceModalButtonProps) {
  const { t } = useTranslate();

  const {
    control,
    registerFields,
    validationErrors,
    resetPayInvoiceForm,
    handlePayInvoice,
    payInvoiceLoading,
    payInvoiceErrorMessage,
    watchTransactionFee
  } = useConfirmPaymentInvoiceForm({
    invoiceId,
    invoiceAmount,
    cacheKeys
  });

  return (
    <FormModalButton
      className={
        className ||
        'py-1.5 pl-3 pr-3 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base border border-gray-300 dark:border-gray-700 shadow-sm bg-white dark:bg-gray-800 text-gray-500 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 self-start ml-2 my-1'
      }
      disabled={disabled}
      form={CONFIRM_PAYMENT_INVOICE_FORM}
      i18nSubmitText={invoicesKeys.confirmITC}
      icon={icon}
      iconClassName={iconClassName}
      tooltipPlacement={tooltipPlacement}
      tooltipI18nText={tooltipI18nText}
      i18nText={i18nText}
      i18nTextClassName={i18nTextClassName}
      i18nTitle={`${t(invoicesKeys.confirmInvoicePayment)} #${invoiceId}`}
      isLoading={payInvoiceLoading}
      onOpen={resetPayInvoiceForm}
      onSubmit={handlePayInvoice}
    >
      <ConfirmPaymentInvoiceForm
        form={CONFIRM_PAYMENT_INVOICE_FORM}
        control={control}
        isLoading={payInvoiceLoading}
        registerPaymentDate={registerFields.registerPaymentDate}
        registerConfirmationId={registerFields.registerConfirmationId}
        paymentDateValidationError={validationErrors.paymentDateValidationError}
        confirmationIdValidationError={
          validationErrors.confirmationIdValidationError
        }
        invoiceAmount={invoiceAmount}
        watchTransactionFee={watchTransactionFee}
      />

      <div className="px-4">
        <AlertMessage message={payInvoiceErrorMessage} />
      </div>
    </FormModalButton>
  );
}

export default ConfirmPaymentInvoiceModalButton;

import * as yup from 'yup';

import { ConfirmPaymentInvoiceFields } from '../../ConfirmPaymentInvoiceForm.types';

import { formsErrors } from '../../../../../../../locales/keys';

export const confirmPaymentInvoiceFormSchema = yup.object({
  [ConfirmPaymentInvoiceFields.PAYMENT_DATE]: yup
    .string()
    .required(formsErrors.required),
  [ConfirmPaymentInvoiceFields.TRANSACTION_FEE]: yup
    .number()
    .nullable()
    .required(formsErrors.required)
});

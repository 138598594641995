import {
  PayoneerCheckoutAttemptID,
  PayoneerCheckoutAttemptUUID,
  PayoneerCheckoutAttemptNanoID
} from './payoneerCheckoutAttemptsTypes';

const baseUrl = 'payoneer_checkout_attempts';

export class PayoneerCheckoutAttemptBffUrl {
  static show(
    invoiceUuid:
      | PayoneerCheckoutAttemptUUID
      | PayoneerCheckoutAttemptNanoID
      | PayoneerCheckoutAttemptID
  ) {
    return `${baseUrl}/${invoiceUuid}`;
  }

  static create() {
    return baseUrl;
  }
}

import React, { Fragment, useEffect, useState } from 'react';

import { PathUrl } from '../../../../../types';

import { useTheme } from '../../../../../app/hooks/useTheme';

import { generateNanoId } from '../../../../../utils/generateNanoId';

import {
  PayoneerCheckoutPayment,
  PayoneerCheckoutPaymentBeforeOtherWidgetRun
} from '../../../../payoneerCheckout/utils/PayoneerCheckoutPayment';
import {
  payButtonId,
  payButtonContainerId,
  paymentNetworksId
} from '../../../../payoneerCheckout/utils/PayoneerCheckoutPayment/payoneerCheckoutPaymentConstants';

interface InvoicePayoneerCheckoutPaymentContainerProps {
  listUrl: PathUrl;
  widgetId?: string;
  beforeOtherWidgetRun?: PayoneerCheckoutPaymentBeforeOtherWidgetRun;
}

function InvoicePayoneerCheckoutPaymentContainer({
  listUrl,
  widgetId,
  beforeOtherWidgetRun
}: InvoicePayoneerCheckoutPaymentContainerProps) {
  const [paymentKey, setPaymentKey] = useState<string>(generateNanoId());

  const { theme } = useTheme();

  useEffect(() => {
    if (listUrl) {
      PayoneerCheckoutPayment.runWidget({
        theme,
        listUrl,
        widgetId,
        beforeOtherWidgetRun
      });
    }

    return () => {
      setPaymentKey(generateNanoId());
    };
  }, [theme, listUrl, widgetId, beforeOtherWidgetRun]);

  if (!listUrl) {
    return null;
  }

  return (
    <Fragment key={paymentKey}>
      <div id={paymentNetworksId} className="payment-networks-container" />
      <div id={payButtonContainerId} className="submit-buttons-container">
        <button
          type="button"
          id={payButtonId}
          className="py-2 pl-2 pr-4 space-x-1 rounded-md flex m-auto items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base text-white bg-blue-600 shadow-sm hover:bg-blue-500 focus:bg-blue-500 hover:shadow-md w-72 max-w-full justify-center bg-blue-600 hover:bg-blue-700"
        />
      </div>
    </Fragment>
  );
}

export default InvoicePayoneerCheckoutPaymentContainer;

import {
  FetchInvoiceGqlQuery,
  FetchInvoicesCacheKey,
  InvoiceID,
  InvoiceNanoID,
  InvoiceUUID
} from '../../invoicesTypes';

import { useFinShowQuery } from '../../../common/hooks/base/reactQuery/useFinShowQuery';

export interface FetchInvoiceResponse<FetchInvoiceType> {
  status: string;
  invoice: FetchInvoiceType;
  error?: Error;
}

export interface InvoiceOptions {
  invoiceUuid: InvoiceUUID | InvoiceNanoID | InvoiceID;
  cacheKey: FetchInvoicesCacheKey;
  options?: {
    cacheTime?: number;
    enabled?: boolean;
    enabledPlaceholder?: boolean;
  };
  query: FetchInvoiceGqlQuery;
}

const itemKey = 'invoice';

function useFinInvoice<FetchInvoiceType>({
  invoiceUuid,
  cacheKey,
  options,
  query
}: InvoiceOptions) {
  const {
    item,
    itemErrorData,
    itemError,
    itemFetched,
    itemFetching,
    itemLoading,
    itemIsPlaceholderData,
    refetchItem
  } = useFinShowQuery<FetchInvoiceResponse<FetchInvoiceType>, FetchInvoiceType>(
    {
      cacheKey,
      itemKey,
      uuid: invoiceUuid,
      options,
      query
    }
  );

  return {
    invoice: item,
    invoiceErrorData: itemErrorData,
    invoiceError: itemError,
    invoiceFetched: itemFetched,
    invoiceFetching: itemFetching,
    invoiceLoading: itemLoading,
    invoiceIsPlaceholderData: itemIsPlaceholderData,
    refetchInvoice: refetchItem
  };
}

export default useFinInvoice;

import { BffApiCreateItemError } from '../../../../types';

import {
  PayoneerAttemptInvoiceID,
  PayoneerAttemptPaymentUrl,
  CreatePayoneerAttemptCacheKeys
} from '../../payoneerAttemptsTypes';

import { useBffCreateQuery } from '../../../common/hooks/base/reactQuery/useBffCreateQuery';

import { PayoneerAttemptBffUrl } from '../../PayoneerAttemptBffUrl';

interface CreatePayoneerAttemptOptions {
  cacheKeys?: CreatePayoneerAttemptCacheKeys;
}

export interface CreatePayoneerAttemptInput {
  payoneerAttempt: {
    invoiceId: PayoneerAttemptInvoiceID;
  };
}

export type CreatePayoneerAttemptError = BffApiCreateItemError;

interface CreatePayoneerAttemptResponseType {
  paymentUrl: PayoneerAttemptPaymentUrl;
}

function useCreatePayoneerAttempt({
  cacheKeys
}: CreatePayoneerAttemptOptions = {}) {
  const { createQuery, createQueryErrorMessage, createQueryLoading } =
    useBffCreateQuery<
      CreatePayoneerAttemptInput,
      CreatePayoneerAttemptResponseType,
      CreatePayoneerAttemptError,
      CreatePayoneerAttemptResponseType
    >({ url: PayoneerAttemptBffUrl.create(), cacheKeys });

  return {
    createPayoneerAttemptLoading: createQueryLoading,
    createPayoneerAttemptErrorMessage: createQueryErrorMessage,
    createPayoneerAttempt: createQuery
  };
}

export default useCreatePayoneerAttempt;

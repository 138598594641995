import { PayoneerAttemptID } from './payoneerAttemptsTypes';

const baseUrl = 'payoneer_attempts';

export class PayoneerAttemptBffUrl {
  static index() {
    return baseUrl;
  }

  static create() {
    return baseUrl;
  }

  static show(payoneerAttemptId: PayoneerAttemptID) {
    return `${baseUrl}/${payoneerAttemptId}`;
  }

  static checkAndPay(payoneerAttemptId: PayoneerAttemptID) {
    return `${baseUrl}/${payoneerAttemptId}/check_and_pay`;
  }
}

import { useCallback } from 'react';

import { useChargebackInvoice } from '../../../hooks/useChargebackInvoice';

import { ClassName, I18nText, IsDisabled } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';
import { FetchInvoicesCacheKeys, InvoiceID } from '../../../invoicesTypes';

import { ConfirmModalButton } from '../../../../../helpers/buttons/ConfirmModalButton';

import { invoicesKeys, words } from '../../../../../locales/keys';

interface ChargebackInvoiceModalButtonProps {
  cacheKeys?: FetchInvoicesCacheKeys;
  className?: ClassName;
  disabled?: IsDisabled;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  invoiceId: InvoiceID;
  i18nText?: I18nText;
  tooltipI18nText?: I18nText;
}
function ChargebackInvoiceModalButton({
  cacheKeys,
  className,
  disabled,
  icon,
  iconClassName,
  invoiceId,
  i18nText,
  tooltipI18nText
}: ChargebackInvoiceModalButtonProps) {
  const {
    chargebackInvoice,
    chargebackInvoiceErrorMessage,
    chargebackInvoiceLoading,
    chargebackInvoiceReset
  } = useChargebackInvoice({ cacheKeys });

  const handleChargebackInvoice = useCallback(
    () => chargebackInvoice({ invoiceId }),
    [chargebackInvoice, invoiceId]
  );

  return (
    <ConfirmModalButton
      className={
        className ||
        'flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap'
      }
      disabled={chargebackInvoiceLoading || disabled}
      errorMessage={chargebackInvoiceErrorMessage}
      i18nButtonText={i18nText}
      i18nSubmitText={words.yes}
      i18nCancelText={words.no}
      i18nText={invoicesKeys.areYouSureYouWantToCancelThisInvoice}
      i18nTitle={invoicesKeys.chargeback}
      tooltipI18nText={tooltipI18nText}
      icon={icon}
      iconClassName={iconClassName}
      isLoading={chargebackInvoiceLoading}
      modalIcon={IconsEnum.EXCLAMATION}
      modalIconClassName="h-6 w-6 text-red-600"
      onClose={chargebackInvoiceReset}
      onSubmit={handleChargebackInvoice}
    />
  );
}

export default ChargebackInvoiceModalButton;

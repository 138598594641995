import React, { useCallback } from 'react';
import {
  PayPalScriptProvider,
  PayPalButtons,
  ReactPayPalScriptOptions,
  usePayPalScriptReducer
} from '@paypal/react-paypal-js';
import { OnApproveData, OnApproveActions } from '@paypal/paypal-js';

import { IsLoading } from '../../../../../types';

import { InvoiceID, FetchInvoicesCacheKeys } from '../../../invoicesTypes';
import { PaypalAttemptOrderID } from '../../../../paypalAttempts/paypalAttemptsTypes';

import { useCreatePaypalAttempt } from '../../../../paypalAttempts/hooks/useCreatePaypalAttempt';
import { useCheckAndPayPaypalByOrderId } from '../../../../paypalAttempts/hooks/useCheckAndPayPaypalByOrderId';
import { useToastNotification } from '../../../../../common/hooks/useToastNotification';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { Loading } from '../../../../../helpers/Loading';

import { words, billsKeys } from '../../../../../locales/keys';

import { InvoiceCache } from '../../../InvoiceCache';
import { PAYPAL_CLIENT_ID } from '../../../../../config';

const InvoicePaypalPaymentContainerLoading = ({
  checkAndPayPaypalByOrderIdLoading
}: {
  checkAndPayPaypalByOrderIdLoading?: IsLoading;
}) => {
  const [{ isPending }] = usePayPalScriptReducer();
  if (!isPending && !checkAndPayPaypalByOrderIdLoading) {
    return null;
  }
  return <Loading addClass="flex p-2" />;
};

const initialOptions: ReactPayPalScriptOptions = {
  clientId: PAYPAL_CLIENT_ID,
  currency: 'USD',
  intent: 'capture',
  components: ['buttons']
};

interface InvoicePaypalPaymentContainerProps {
  invoiceId: InvoiceID;
  cacheKeys?: FetchInvoicesCacheKeys;
  onSuccess?: () => void;
}

function InvoicePaypalPaymentContainer({
  invoiceId,
  cacheKeys,
  onSuccess
}: InvoicePaypalPaymentContainerProps) {
  const { createPaypalAttemptErrorMessage, createPaypalAttempt } =
    useCreatePaypalAttempt({});
  const { checkAndPayPaypalByOrderId, checkAndPayPaypalByOrderIdLoading } =
    useCheckAndPayPaypalByOrderId({
      cacheKeys: [...(cacheKeys || []), InvoiceCache.showCacheKey()]
    });

  const { showToastI18nNotification: showToastError } = useToastNotification({
    appearance: 'error',
    i18nMessage: words.error
  });
  const { showToastI18nNotification: showToastPaymentFailed } =
    useToastNotification({
      appearance: 'warning',
      i18nMessage: billsKeys.paymentFailed
    });
  const { showToastI18nNotification: showToastSuccess } = useToastNotification({
    appearance: 'success',
    i18nMessage: words.success
  });

  const createOrder = useCallback<
    () => Promise<PaypalAttemptOrderID>
  >(async () => {
    return createPaypalAttempt({
      invoiceId,
      withPaymentUrl: false,
      withOrderId: true
    }).then((res) => res?.createPaypalAttempt?.record?.orderId);
  }, [invoiceId, createPaypalAttempt]);

  const onApprove = useCallback<
    (data: OnApproveData, actions: OnApproveActions) => Promise<void>
  >(
    async (data, actions) => {
      try {
        const orderResponse = await checkAndPayPaypalByOrderId({
          orderId: data.orderID
        });

        const orderData = orderResponse?.checkAndPayPaypalByOrderId?.content;

        // Three cases to handle:
        //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
        //   (2) Other non-recoverable errors -> Show a failure message
        //   (3) Successful transaction -> Show confirmation or thank you message

        const errorDetail = orderData?.details?.[0];

        if (errorDetail?.issue === 'INSTRUMENT_DECLINED') {
          // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
          // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
          showToastPaymentFailed();
          return actions.restart();
        } else if (errorDetail) {
          // (2) Other non-recoverable errors -> Show a failure message
          throw new Error(
            `${errorDetail.description} (${orderData?.debug_id})`
          );
        } else if (!orderData?.purchase_units) {
          throw new Error(JSON.stringify(orderData));
        } else {
          // (3) Successful transaction -> Show confirmation or thank you message
          // Or go to another URL:  actions.redirect('thank_you.html');
          console.log(
            'Capture result',
            orderData,
            JSON.stringify(orderData, null, 2)
          );
          showToastSuccess();
          onSuccess?.();
        }
      } catch (error) {
        console.error(error);
        showToastError();
      }
    },
    [
      checkAndPayPaypalByOrderId,
      showToastPaymentFailed,
      showToastSuccess,
      showToastError,
      onSuccess
    ]
  );

  return (
    <>
      <AlertMessage message={createPaypalAttemptErrorMessage} />
      <div id="paypal-checkout">
        <PayPalScriptProvider options={initialOptions}>
          <InvoicePaypalPaymentContainerLoading
            checkAndPayPaypalByOrderIdLoading={
              checkAndPayPaypalByOrderIdLoading
            }
          />

          <PayPalButtons
            style={{ layout: 'vertical' }}
            createOrder={createOrder}
            onApprove={onApprove}
          />
        </PayPalScriptProvider>
      </div>
    </>
  );
}

export default InvoicePaypalPaymentContainer;

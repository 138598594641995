export interface ConfirmPaymentInvoiceFormData {
  paymentDate: string;
  transactionFee: number;
  confirmationId: string;
  comment: string;
}

export enum ConfirmPaymentInvoiceFields {
  TRANSACTION_FEE = 'transactionFee',
  PAYMENT_DATE = 'paymentDate',
  CONFIRMATION_ID = 'confirmationId',
  COMMENT = 'comment'
}

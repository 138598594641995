import { useMutation, useQueryClient } from 'react-query';
import isArray from 'lodash/isArray';

import {
  CreateItemGqlQuery,
  CreateItemCacheKeys
} from '../../../../../../types';

import { createFinItem } from '../baseActions/createFinItem';

import {
  parseRequestError,
  ErrorType
} from '../../../../../../utils/parseRequestError';

interface CreateQueryOptions<CreateQueryInput, CreateQueryResponse> {
  action: string;
  query: CreateItemGqlQuery;
  cacheKeys?: CreateItemCacheKeys;
  mutationFn?: (queryInput: CreateQueryInput) => Promise<CreateQueryResponse>;
}

export interface CreateQueryContext<ItemType> {
  previousData: ItemType;
}

function useFinCreateQuery<
  CreateQueryInput,
  CreateQueryResponse,
  CreateQueryError extends ErrorType,
  ItemType
>({
  action,
  cacheKeys,
  query,
  mutationFn
}: CreateQueryOptions<CreateQueryInput, CreateQueryResponse>) {
  const queryClient = useQueryClient();

  const { data, error, isLoading, mutateAsync, reset } = useMutation<
    CreateQueryResponse,
    CreateQueryError,
    CreateQueryInput,
    CreateQueryContext<ItemType>
  >(
    mutationFn ||
      ((queryInput) =>
        createFinItem<CreateQueryInput, CreateQueryResponse>({
          action,
          query,
          queryInput
        })),
    {
      onSettled: (response) => {
        if (isArray(cacheKeys)) {
          cacheKeys.map((eachCacheKey) =>
            queryClient.invalidateQueries(eachCacheKey)
          );
        }
      }
    }
  );

  return {
    createQueryData: data,
    createQueryError: error,
    createQueryLoading: isLoading,
    createQueryErrorMessage: parseRequestError(error),
    createQuery: mutateAsync,
    createQueryReset: reset
  };
}

export default useFinCreateQuery;

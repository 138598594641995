import { BffApiCreateItemError } from '../../../../types';

import {
  PayoneerCheckoutAttemptInvoiceID,
  PayoneerCheckoutAttemptPaymentDataLinksSelf,
  CreatePayoneerCheckoutAttemptCacheKeys
} from '../../payoneerCheckoutAttemptsTypes';

import { useBffCreateQuery } from '../../../common/hooks/base/reactQuery/useBffCreateQuery';

import { PayoneerCheckoutAttemptBffUrl } from '../../PayoneerCheckoutAttemptBffUrl';

interface CreatePayoneerCheckoutAttemptOptions {
  cacheKeys?: CreatePayoneerCheckoutAttemptCacheKeys;
}

export interface CreatePayoneerCheckoutAttemptInput {
  payoneerCheckoutAttempt: {
    invoiceId: PayoneerCheckoutAttemptInvoiceID;
  };
}

export type CreatePayoneerCheckoutAttemptError = BffApiCreateItemError;

interface CreatePayoneerCheckoutAttemptResponseType {
  paymentData: {
    links: {
      self: PayoneerCheckoutAttemptPaymentDataLinksSelf;
    };
  };
}

function useCreatePayoneerCheckoutAttempt({
  cacheKeys
}: CreatePayoneerCheckoutAttemptOptions = {}) {
  const { createQuery, createQueryErrorMessage, createQueryLoading } =
    useBffCreateQuery<
      CreatePayoneerCheckoutAttemptInput,
      CreatePayoneerCheckoutAttemptResponseType,
      CreatePayoneerCheckoutAttemptError,
      CreatePayoneerCheckoutAttemptResponseType
    >({ url: PayoneerCheckoutAttemptBffUrl.create(), cacheKeys });

  return {
    createPayoneerCheckoutAttemptLoading: createQueryLoading,
    createPayoneerCheckoutAttemptErrorMessage: createQueryErrorMessage,
    createPayoneerCheckoutAttempt: createQuery
  };
}

export default useCreatePayoneerCheckoutAttempt;

import { gql } from 'graphql-request';

export const CHECK_AND_PAY_PAYPAL_BY_ORDER_ID_QUERY = gql`
  mutation CheckAndPayPaypalByOrderId($orderId: String!) {
    checkAndPayPaypalByOrderId(input: { orderId: $orderId }) {
      content
      status
    }
  }
`;

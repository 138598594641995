import { BffApiCreateItemError, UpdateItemGqlError } from '../../../../types';

import {
  PaypalAttemptID,
  PaypalAttemptPaymentUrl,
  PaypalAttemptOrderID,
  PaypalAttemptWithPaymentUrl,
  PaypalAttemptWithOrderID,
  CreatePaypalAttemptCacheKeys
} from '../../paypalAttemptsTypes';

import { InvoiceID } from '../../../invoices/invoicesTypes';

import { useFinCreateQuery } from '../../../common/hooks/base/reactQuery/useFinCreateQuery';
import { CREATE_PAYPAL_ATTEMPT_QUERY } from '../../queries/createPaypalAttempt.query';
import { UpdateBillGqlStatus } from '../../../bills/billsTypes';

interface CreatePaypalAttemptOptions {
  cacheKeys?: CreatePaypalAttemptCacheKeys;
}

interface CreatePaypalAttemptInput {
  invoiceId: InvoiceID;
  withPaymentUrl?: PaypalAttemptWithPaymentUrl;
  withOrderId?: PaypalAttemptWithOrderID;
}

type CreatePaypalAttemptError = BffApiCreateItemError;

interface CreatePaypalAttemptItemType {
  id: PaypalAttemptID;
  paymentUrl: PaypalAttemptPaymentUrl;
  orderId: PaypalAttemptOrderID;
}

interface CreatePaypalAttemptResponseType {
  createPaypalAttempt: {
    status: UpdateBillGqlStatus;
    record: CreatePaypalAttemptItemType;
    errors: UpdateItemGqlError;
    recordId: InvoiceID;
  };
}

const action = 'createPaypalAttempt';

function useCreatePaypalAttempt({
  cacheKeys
}: CreatePaypalAttemptOptions = {}) {
  const { createQuery, createQueryErrorMessage, createQueryLoading } =
    useFinCreateQuery<
      CreatePaypalAttemptInput,
      CreatePaypalAttemptResponseType,
      CreatePaypalAttemptError,
      CreatePaypalAttemptItemType
    >({ action, query: CREATE_PAYPAL_ATTEMPT_QUERY, cacheKeys });

  return {
    createPaypalAttemptLoading: createQueryLoading,
    createPaypalAttemptErrorMessage: createQueryErrorMessage,
    createPaypalAttempt: createQuery
  };
}

export default useCreatePaypalAttempt;

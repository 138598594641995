import React, { useCallback } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';

import { InvoiceID } from '../../../invoicesTypes';
import { StripeAttemptSessionClientSecret } from '../../../../stripeAttempts/stripeAttemptsTypes';

import { useCreateStripeAttempt } from '../../../../stripeAttempts/hooks/useCreateStripeAttempt';

import { AlertMessage } from '../../../../../helpers/AlertMessage';

import { PayoneerCheckoutPaymentBeforeOtherWidgetRun } from '../../../../payoneerCheckout/utils/PayoneerCheckoutPayment';

import { STRIPE_API_KEY } from '../../../../../config';

const stripePromise = loadStripe(STRIPE_API_KEY);

interface InvoiceStripePaymentContainerProps {
  invoiceId: InvoiceID;
  widgetId?: string;
  beforeOtherWidgetRun?: PayoneerCheckoutPaymentBeforeOtherWidgetRun;
}

function InvoiceStripePaymentContainer({
  invoiceId
}: InvoiceStripePaymentContainerProps) {
  const { createStripeAttemptErrorMessage, createStripeAttempt } =
    useCreateStripeAttempt({});

  const fetchClientSecret = useCallback<
    () => Promise<StripeAttemptSessionClientSecret>
  >(async () => {
    return createStripeAttempt({
      invoiceId,
      withPaymentUrl: false,
      withSessionClientSecret: true
    }).then((res) => res?.createStripeAttempt?.record?.sessionClientSecret);
  }, [invoiceId, createStripeAttempt]);

  const options = { fetchClientSecret };

  return (
    <>
      <AlertMessage message={createStripeAttemptErrorMessage} />
      <div id="stripe-checkout">
        <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      </div>
    </>
  );
}

export default InvoiceStripePaymentContainer;
